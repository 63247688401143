import { AbstractFieldComponent } from '@app/form/field/abstract-field.component';
import { ChoiceChipsInterface } from '@app/form/field/choice-chips/choice-chips.interface';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'scout-form-choice-chips',
  templateUrl: './choice-chips.component.html',
  styleUrls: ['./choice-chips.component.scss'],
})
export class ScoutFormChoiceChipsComponent extends AbstractFieldComponent {

  @Input() public defaultValue: ChoiceChipsInterface;
  @Input() public values: ChoiceChipsInterface[];

  @Output() public selectedValue = new EventEmitter<ChoiceChipsInterface>();

  constructor() {
    super();
  }

  public setAmount(value: ChoiceChipsInterface): void {
    this.selectedValue.emit(value);
  }
}
