import { addSeconds, getUnixTime } from 'date-fns';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { Observable, of, Subject, throwError } from 'rxjs';
import { ScoutDataService } from '@shared/services/data/data.service';
import { TokenRequest } from '@shared/authentication/oauth2/token-request';
import { TokenResponse } from '@shared/authentication/oauth2/token-response';

@Injectable({
  providedIn: 'root',
})
export class ScoutAuthenticationService {

  public static hasValidToken(): boolean {
    const token = (localStorage.getItem('oauth2_access_token') || '');
    const timeout = localStorage.getItem('oauth2_timeout');

    if (token && timeout) {
      return (getUnixTime(new Date()) <= parseInt(timeout, 10));
    }

    return false;
  }

  public static setRedirectUrl(url: string): void {
    localStorage.setItem('login_redirect_url', url);
  }

  public static clearRedirectUrl(): void {
    localStorage.removeItem('login_redirect_url');
  }

  constructor(private dataService: ScoutDataService,
              private navController: NavController) {
  }

  public getRedirectUrl(): string {
    const storageUrl = localStorage.getItem('login_redirect_url');
    const defaultUrl = `/scoutbereich/scouting`;

    return storageUrl ? storageUrl : defaultUrl;
  }

  public login(parameters: TokenRequest) {
    return this.dataService
      .login(parameters)
      .pipe(map((data: TokenResponse) => {
        const timeout = getUnixTime(addSeconds(new Date(), data.expires_in));
        const redirectUrl = this.getRedirectUrl();

        const newResponse = {
          success: true,
          oauth2AccessToken: data.access_token,
          oauth2Timeout: timeout.toString(),
          oauth2RefreshToken: data.refresh_token,
          redirectUrl,
        };

        localStorage.setItem('oauth2_grant_type', parameters.grant_type);
        localStorage.setItem('oauth2_access_token', newResponse.oauth2AccessToken);
        localStorage.setItem('oauth2_timeout', newResponse.oauth2Timeout);
        localStorage.setItem('oauth2_refresh_token', newResponse.oauth2RefreshToken);

        return newResponse;

      }, (data: any) => {
        let result = {success: false, errorCode: 'UNKNOWN'};

        if (parameters) {
          if (data.error) {
            result = {success: false, errorCode: data.error.error.toUpperCase()};

            if (data.status === 400 && data.error.error === 'invalid_grant') {
              this.logout();
            }
          }
        }

        return throwError(result);
      }));
  }

  public logout() {
    localStorage.clear();

    this.initOAuth()
      .subscribe({
        complete: () => this.navController.navigateBack(['/benutzerkonto/einloggen']),
      });
  }

  public initOAuth(): Observable<object> {
    const subject = new Subject<object>();

    if (ScoutAuthenticationService.hasValidToken()) {
      return of<object>();
    }

    const request: TokenRequest = new TokenRequest(
      'client_credentials',
      environment.oauthClientId,
      environment.oauthClientSecret,
    );

    this.login(request)
      .subscribe({
        next: (response) => subject.next(response),
        error: (error) => subject.error(error),
        complete: () => subject.complete(),
      });

    return subject.asObservable();
  }
}
