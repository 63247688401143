<div *ngIf="field?.invalid && field?.touched"
     @fade-toggle
     class="d-flex mt-4">
  <div class="ion-justify-content-start ion-align-items-baseline"
       *ngFor="let item of filterErrors(field)">
    <ion-text color="danger">
      <fa-icon [icon]="['far', 'exclamation-circle']"
               class="font-size-sm mr-4">
      </fa-icon>
    </ion-text>
    <ion-text color="danger">
       <span
         class="font-size-xs font-is-semibold">{{translationPrefix + (item?.key === 'pattern' ? patternName : item?.key) | uppercase | transloco : item?.val}}</span>
    </ion-text>
  </div>
</div>
