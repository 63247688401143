import {
  faAngry,
  faArrowAltSquareDown,
  faBadgeCheck,
  faBoxCheck,
  faBusinessTime,
  faCameraAlt,
  faFrown,
  faGrinHearts,
  faMapMarkedAlt as fadMapMarkedAlt,
  faMapMarkerAltSlash,
  faCommentCheck,
  faMeh,
  faPhone as fadPhone,
  faSmile,
  faStreetView,
  faTasks as fadTasks,
  faUserCircle
} from '@fortawesome/pro-duotone-svg-icons';

import {
  faCheckCircle as farCheckCircle,
  faCircle as farCircle,
  faInfoCircle,
  faExclamationCircle,
  faQuestionCircle
} from '@fortawesome/pro-regular-svg-icons';

import {
  faBox,
  faCheckCircle as fasCheckCircle,
  faChevronRight,
  faCircle as fasCircle,
  faCoins,
  faDirections,
  faEnvelope,
  faExternalLink,
  faInfo,
  faList,
  faLocation,
  faMapMarkedAlt as fasMapMarkedAlt,
  faMapMarkerCheck,
  faMapMarkerQuestion,
  faMapMarkerTimes,
  faPhone as fasPhone,
  faSignOutAlt,
  faSlidersH,
  faTasks as fasTasks,
  faTimesCircle,
  faTrash,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';

export const ICONS = [
  faAngry,
  faArrowAltSquareDown,
  faBadgeCheck,
  faBox,
  faBoxCheck,
  faBusinessTime,
  faCameraAlt,
  faChevronRight,
  faCoins,
  faCommentCheck,
  faDirections,
  fadMapMarkedAlt,
  fadPhone,
  fadTasks,
  faEnvelope,
  faExclamationCircle,
  faExternalLink,
  faFrown,
  faGrinHearts,
  faInfo,
  faInfoCircle,
  faList,
  faLocation,
  faMapMarkerAltSlash,
  faMapMarkerCheck,
  faMapMarkerQuestion,
  faMapMarkerTimes,
  faMeh,
  faQuestionCircle,
  farCheckCircle,
  farCircle,
  fasCheckCircle,
  fasCircle,
  faSignOutAlt,
  faSlidersH,
  fasMapMarkedAlt,
  faSmile,
  fasPhone,
  fasTasks,
  faStreetView,
  faTimesCircle,
  faTrash,
  faUser,
  faUserCircle,
];
