import { Injectable } from '@angular/core';
import { ScoutUserModel } from '@shared/authentication/user/user.model';
import { SerializationUtil } from '@shared/util/serialization/serialization.util';

@Injectable({
  providedIn: 'root',
})
export class ScoutUserStorage {
  public getUser(): ScoutUserModel {
    const userString: string = localStorage.getItem('user') as string;

    if (userString) {
      const user: ScoutUserModel = JSON.parse(userString) as ScoutUserModel;

      if (user) {
        return SerializationUtil.toInstance(new ScoutUserModel(), user);
      }
    }

    return null;
  }

  public setUser(user: ScoutUserModel): ScoutUserStorage {
    localStorage.setItem('user', JSON.stringify(user));

    return this;
  }

  public clearUser() {
    localStorage.removeItem('user');
  }
}
