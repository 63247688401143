import { Injectable } from '@angular/core';
import { ScoutUserEventModel } from '@shared/authentication/user/user-event.model';
import { ScoutUserModel } from '@shared/authentication/user/user.model';
import { ScoutUserService } from '@shared/authentication/user/user.service';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScoutUserEventService {
  private notify = new Subject<ScoutUserEventModel>();

  constructor(private userService: ScoutUserService) {
  }

  public triggerLogin(user: ScoutUserModel) {
    this.notify.next({
      type: ScoutUserEventModel.LOGIN,
      data: user,
    } as ScoutUserEventModel);
  }

  public triggerUpdate() {
    this.userService
      .load()
      .subscribe((user: ScoutUserModel) => {
        this.notify.next({
          type: ScoutUserEventModel.UPDATED,
          data: user,
        } as ScoutUserEventModel);
      });
  }

  public triggerLogout() {
    this.notify.next({
      type: ScoutUserEventModel.LOGOUT,
    } as ScoutUserEventModel);
  }

  public onEvent(): Observable<ScoutUserEventModel> {
    return this.notify.asObservable();
  }

  public onLogin(): Observable<ScoutUserEventModel> {
    return this
      .onEvent()
      .pipe(filter((e: ScoutUserEventModel) => e.type === ScoutUserEventModel.LOGIN));

  }

  public onUpdate(): Observable<ScoutUserEventModel> {
    return this
      .onEvent()
      .pipe(filter((e: ScoutUserEventModel) => e.type === ScoutUserEventModel.UPDATED));
  }

  public onLogout(): Observable<ScoutUserEventModel> {
    return this
      .onEvent()
      .pipe(filter((e: ScoutUserEventModel) => e.type === ScoutUserEventModel.LOGOUT));
  }
}
