import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'scout-stacked-icons',
  templateUrl: './stacked-icons.component.html',
  styleUrls: ['./stacked-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoutStackedIconsComponent {
  @Input() public iconFront: IconProp;
  @Input() public iconBack: IconProp;
}
