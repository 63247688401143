import { Component, ViewChild } from '@angular/core';
import { IonSlides, NavController } from '@ionic/angular';
import { ScoutDataService } from '@shared/services/data/data.service';
import { ScoutUserModel } from '@shared/authentication/user/user.model';
import { ScoutUserStorage } from '@shared/authentication/user/user-storage.service';

@Component({
  selector: 'scout-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent {

  public isLoading = false;
  public sliderOptions = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: false,
    speed: 400,
  };

  @ViewChild('tutorialSlider') public tutorialSlider: IonSlides;

  constructor(private dataService: ScoutDataService,
              private userStorage: ScoutUserStorage,
              private navController: NavController) {
  }

  public finishTutorial() {
    this.tutorialSlider
      .lockSwipes(true)
      .then(() => {
        this.isLoading = true;

        this.dataService
          .patchUser({hasSeenTutorial: true})
          .subscribe((user: ScoutUserModel) => {
            this.isLoading = false;
            this.userStorage.setUser(user);
            this.navController.navigateForward(['/scoutbereich/scouting']);
          }, () => {
            this.isLoading = false;
          });
      });
  }
}
