/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { BehaviorSubject, Observable } from 'rxjs';
import { FILTER_CONFIG } from '@app/tabs/scouting-tab/services/filter/filter.config';
import { Injectable } from '@angular/core';
import { ScoutFilterRangeInterface } from '@app/tabs/scouting-tab/services/filter/filter.interface';
import { SearchProfileInterface } from '@shared/interfaces/shared.interface';

@Injectable({
  providedIn: 'root'
})
export class ScoutFilterService {

  private readonly defaultRangeState = {searchRadius: FILTER_CONFIG.SEARCH_RADIUS_PRESET, triggerSearchUpdate: false};
  private _activeSearchProfile: SearchProfileInterface;
  private _failLocationsVisible = true;
  private _pendingLocationsVisible = true;
  private _scoutsVisible = true;
  private _regionsVisible = true;
  private _successLocationsVisible = true;
  private availableLocationsVisible = new BehaviorSubject<boolean>(true);
  private availableLocationsVisible$ = this.availableLocationsVisible.asObservable();
  private searchProfiles = new BehaviorSubject<SearchProfileInterface[]>([]);
  private searchProfiles$ = this.searchProfiles.asObservable();
  private searchRadius = new BehaviorSubject<ScoutFilterRangeInterface>(this.defaultRangeState);
  private searchRadius$ = this.searchRadius.asObservable();

  get pendingLocationsVisible(): boolean {
    return this._pendingLocationsVisible;
  }

  set pendingLocationsVisible(value: boolean) {
    this._pendingLocationsVisible = value;
  }

  get failLocationsVisible(): boolean {
    return this._failLocationsVisible;
  }

  set failLocationsVisible(value: boolean) {
    this._failLocationsVisible = value;
  }

  get successLocationsVisible(): boolean {
    return this._successLocationsVisible;
  }

  set successLocationsVisible(value: boolean) {
    this._successLocationsVisible = value;
  }

  get scoutsVisible(): boolean {
    return this._scoutsVisible;
  }

  set scoutsVisible(value: boolean) {
    this._scoutsVisible = value;
  }

  get activeSearchProfile(): SearchProfileInterface {
    return this._activeSearchProfile;
  }

  set activeSearchProfile(value: SearchProfileInterface) {
    this._activeSearchProfile = value;
  }

  public get searchRadiusValue() {
    return this.searchRadius.value;
  }

  public onSearchRadiusUpdate(): Observable<ScoutFilterRangeInterface> {
    return this.searchRadius$;
  }

  public setSearchRadiusTo($event: ScoutFilterRangeInterface): void {
    this.searchRadius.next($event);
  }

  public onAvailableLocationsVisibleUpdate(): Observable<boolean> {
    return this.availableLocationsVisible$;
  }

  public setAvailableLocationsVisible($event: boolean): void {
    this.availableLocationsVisible.next($event);
  }

  public get searchProfilesValue() {
    return this.searchProfiles.value;
  }

  public setSearchProfiles(searchProfiles: SearchProfileInterface[]): void {
    this.searchProfiles.next(searchProfiles);
  }

  public onSearchProfilesUpdate(): Observable<SearchProfileInterface[]> {
    return this.searchProfiles$;
  }

  public get regionsVisible(): boolean {
    return this._regionsVisible;
  }

  public set regionsVisible(value: boolean) {
    this._regionsVisible = value;
  }
}
