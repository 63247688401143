import * as Sentry from '@sentry/angular';
import { AppModule } from '@app/app.module';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { enableProdMode } from '@angular/core';
import { environment } from '@env/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { VERSION } from '@env/version';


if (environment.production) {
  Sentry.init({
    dsn: environment.sentryId,
    release: VERSION,
    environment: environment.name
  });

  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

defineCustomElements(window);
