import * as Sentry from '@sentry/angular';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { filter, map, takeUntil } from 'rxjs/operators';
import { MenuController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { OutletService } from '@shared/services/outlet/outlet.service';
import { ScoutFilterService } from '@app/tabs/scouting-tab/services/filter/filter.service';
import { ScoutModalComponent } from '@shared/components/modal/modal.component';
import { ScoutModalService } from '@shared/services/modal/modal.service';
import { ScoutUserEventService } from '@shared/authentication/user/user-event.service';
import { ScoutUserModel } from '@shared/authentication/user/user.model';
import { ScoutUserService } from '@shared/authentication/user/user.service';
import { SwUpdate, type VersionReadyEvent } from '@angular/service-worker';
import { Title } from '@angular/platform-browser';
import { translate, TranslocoService } from '@ngneat/transloco';
import { VERSION } from '@env/version';

@Component({
  selector: 'scout-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {

  private ngUnsubscribe = new Subject<void>();
  public oldSearchRadiusValue: number;
  public oldSearchProfileValue: number;
  public sidebarContentComponent = null;

  constructor(@Inject(Window) private window: Window,
              private outletService: OutletService,
              private activatedRoute: ActivatedRoute,
              private translocoService: TranslocoService,
              private router: Router,
              private modalService: ScoutModalService,
              public menu: MenuController,
              private titleService: Title,
              public updates: SwUpdate,
              public userEventService: ScoutUserEventService,
              public userService: ScoutUserService,
              public filterService: ScoutFilterService) {
    this.setPageTitle();
  }

  public ngOnInit(): void {
    this.subscribeToOverlayContentChanges();
    (window as any).appVersion = VERSION;
    this.checkForAppUpdate();
    this.showAppUpdatedModal();
    if (environment.production) {
      this.getUserForSentry();
    }
  }

  private subscribeToOverlayContentChanges(): void {
    this.outletService
      .getComponent()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(component => this.sidebarContentComponent = component);
  }

  private setPageTitle(): void {
    this.getPageTitleFromRouter()
      .subscribe((activatedRoute: ActivatedRoute) => {
        const data = activatedRoute.snapshot.data;
        let pageTitle;

        if (typeof data.pageTitle === 'function') {
          pageTitle = data.pageTitle(activatedRoute.snapshot);
        } else {
          pageTitle = data.pageTitle;
        }

        pageTitle = pageTitle || 'TITLE';

        this.translocoService
          .selectTranslate(pageTitle)
          .subscribe((title: string) => {
            this.titleService.setTitle(title);
          });
      });
  }

  private getPageTitleFromRouter(): Observable<ActivatedRoute> {
    return this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
      );
  }

  private  checkForAppUpdate(): void {
    this.updates.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
    ).subscribe(() => this.updates.activateUpdate());
  }

  private async showAppUpdatedModal(): Promise<void> {
    const activated = await this.updates.activateUpdate();

    if (!activated) {
      return;
    }

    void this.modalService.createModal({
        componentProps: {
          title: translate('GENERAL.APP_UPDATED.MODAL.TITLE'),
          subtitle: translate('GENERAL.APP_UPDATED.MODAL.SUBTITLE'),
          buttonLabel: translate('GENERAL.OK'),
          icon: 'arrow-alt-square-down'
        },
        component: ScoutModalComponent,
        cssClasses: ['modal--simple']
      });

    this.modalService
        .onDismiss()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => document.location.reload());
  }

  public triggerSearchRadiusUpdate(): void {
    const searchRadius = this.filterService.searchRadiusValue.searchRadius;
    const searchProfile = this.filterService.activeSearchProfile.id;

    this.filterService.setSearchRadiusTo({
      searchRadius,
      triggerSearchUpdate: (this.oldSearchRadiusValue !== searchRadius || this.oldSearchProfileValue !== searchProfile)
    });
  }

  private getUserForSentry(): void {
    if (this.userService.isLoggedIn()) {
      this.setUserInSentry(this.userService.getUser());
    } else {
      this.userEventService
        .onLogin()
        .subscribe(() => this.setUserInSentry(this.userService.getUser()));
    }
  }

  private setUserInSentry(user: ScoutUserModel): void {
    const info = {
      email: user.email,
      id: String(user.id),
      username: user.email,
    };

    Sentry.setUser(info);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
