import { AbstractFieldComponent } from '@app/form/field/abstract-field.component';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'scout-form-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class ScoutFormTextareaComponent extends AbstractFieldComponent {

  @Input() public cols = 5;
  @Input() public placeholder = '';

  constructor() {
    super();
  }
}
