import { ScoutUserModel } from '@shared/authentication/user/user.model';

export class ScoutUserEventModel {
  public static LOGIN = 'login';
  public static UPDATED = 'update';
  public static LOGOUT = 'logout';

  constructor(public type: 'login' | 'update' | 'logout',
              public data?: ScoutUserModel) {
  }
}
