import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScoutModalService {

  private modalInstance = new Subject<any>();
  private modalInstance$ = this.modalInstance.asObservable();

  constructor(public modalController: ModalController) {
  }

  public async createModal({componentProps, component, cssClasses}) {
    const modal = await this.modalController.create({
      canDismiss: true,
      cssClass: ['modal', ...cssClasses],
      component,
      componentProps
    });

    this.handleInstanceDismisses(modal);

    return await modal.present();
  }

  private handleInstanceDismisses(modal) {
    modal
      .onWillDismiss()
      .then((repsonse) => this.modalInstance.next(repsonse));
  }

  public onDismiss(): Observable<any> {
    return this.modalInstance$;
  }
}
