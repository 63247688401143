import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { ScoutAuthenticationService } from '@shared/authentication/authentication.service';
import { ScoutUserService } from '@shared/authentication/user/user.service';

@Injectable({providedIn: 'root'})
export class ScoutAuthenticationGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private navController: NavController,
              private userService: ScoutUserService) {
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.checkLogin(segments[0].path, route);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state.url, route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  checkLogin(url: string, route: ActivatedRouteSnapshot | Route): boolean {
    if (this.userService.isLoggedIn()) {
      return true;
    }

    // Store the attempted URL for redirecting
    if (url !== '/logout') {
      ScoutAuthenticationService.setRedirectUrl(url);
    }

    // Navigate to the login page
    this.navController.navigateForward(['/benutzerkonto/einloggen']);

    return false;
  }
}
