<div *ngIf="!file"
     [@fade-toggle]="!file"
     class="upload-container d-flex flex-column ion-justify-content-center ion-align-items-center w-100 ion-text-center">
  <label for="fileInput"
         class="d-flex flex-column ion-justify-content-between ion-align-items-center">
    <input (change)="fileBrowseHandler()"
           type="file"
           id="fileInput"
           [accept]="accept"
           #fileInput/>
    <fa-icon [icon]="icon"
             class="colored-icon switch-colors">
    </fa-icon>
    <span class="font-is-semibold font-size-xs my-8">{{label}}</span>
  </label>
</div>

<div *ngIf="file"
     [@fade-toggle]="file"
     class="picked-file d-flex ion-justify-content-between ion-align-items-center w-100 p-12">
  <div *ngIf="isImage(file)"
       scoutMediaPreview
       [media]="file"
       class="preview">
  </div>

  <div class="info w-100">
    <ion-text color="dark">
      <h4 class="font-size-md font-is-semibold my-0">
        <span>{{file?.name}}</span>
      </h4>
    </ion-text>
    <ion-text color="medium">
      <p class="font-size-xs font-is-semibold my-0 mr-4">
        <span>{{formatBytes(file?.size)}}</span>
      </p>
    </ion-text>
  </div>

  <ion-fab vertical="bottom"
           horizontal="end"
           slot="fixed">
    <ion-fab-button class="delete"
                    color="light"
                    (click)="deleteFile()">
      <fa-icon [icon]="['fas', 'trash']"></fa-icon>
    </ion-fab-button>
  </ion-fab>
</div>
