import { CommonModule } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '@app/icons/icons.module';
import { IonicModule } from '@ionic/angular';
import { ScoutAlertComponent } from '@shared/components/alert/alert.component';
import { ScoutButtonComponent } from '@shared/components/button/button.component';
import { ScoutContactButtonsComponent } from '@shared/components/contact-buttons/contact-buttons.component';
import { ScoutFormModule } from '../form/form.module';
import { ScoutMapPipe } from './pipes/map.pipe';
import { ScoutModalComponent } from '@shared/components/modal/modal.component';
import { ScoutStackedIconsComponent } from '@shared/components/stacked-icons/stacked-icons.component';
import { TranslocoModule } from '@ngneat/transloco';

const SharedPipes = [
  ScoutMapPipe,
];

const SharedComponents = [
  ScoutAlertComponent,
  ScoutButtonComponent,
  ScoutContactButtonsComponent,
  ScoutStackedIconsComponent,
  ScoutModalComponent,
];

const SharedModules = [
  CommonModule,
  FormsModule,
  IconsModule,
  IonicModule,
  ReactiveFormsModule,
  ScoutFormModule,
  TranslocoModule,
];

const SharedProviders = [
  {
    provide: DEFAULT_CURRENCY_CODE,
    useValue: 'EUR',
  },
];

@NgModule({
  declarations: [
    ...SharedComponents,
    ...SharedPipes,
  ],
  imports: [
    ...SharedModules,
  ],
  providers: [
    ...SharedProviders,
  ],
  exports: [
    ...SharedModules,
    ...SharedComponents,
    ...SharedPipes,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...SharedProviders],
    };
  }
}
