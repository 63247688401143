/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export class TokenRequest {
  constructor(grant_type: string,
              client_id: string,
              client_secret: string);

  constructor(grant_type: string,
              client_id: string,
              client_secret: string,
              username: string,
              password: string);

  constructor(public grant_type: string,
              public client_id: string,
              public client_secret: string,
              public username?: string,
              public password?: string,
              public refresh_token?: string) {
  }
}
