export const API_VERSION = 'v1';
export const API_PATHS = {
  LOCATION: {
    SEARCH: 'location/search',
    DETAILS: 'locations',
  },
  USER: {
    LOGIN: 'oauth/v2/token',
    ME: 'users/me',
    STATUS: 'users/status',
    PATCH: 'user',
    ENVELOPES: 'envelopes',
  },
  SCOUTINGS: {
    POS: 'scoutings',
    IMAGES: 'images'
  },
  SCORINGS: {
    SCORES: 'scorings',
    HISTORY: 'scoring/history'
  },
  SEARCH_PROFILE: {
    ALL: 'searchprofiles'
  },
  TASKS: 'tasks'
};
