import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'scout-form-field-group',
  templateUrl: './field-group.component.html',
  styleUrls: ['./field-group.component.scss'],
})
export class ScoutFormFieldGroupComponent {

  @Input() public formGroup: FormGroup;
  @Input() public name: string;
  @Input() label?: string;
  @Input() hint?: string;
  @Input() patternName?: string;
  @Input() translationPrefix = 'general.form.error.';
}
