import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SpinnerTypes } from '@ionic/core';

@Component({
  selector: 'scout-button',
  templateUrl: './button.component.html',
})
export class ScoutButtonComponent {

  @Input() public buttonType: 'button' | 'reset' | 'submit' = 'button';
  @Input() public color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' = 'primary';
  @Input() public disabled: boolean;
  @Input() public expand: 'full' | 'block' = 'block';
  @Input() public fill: 'clear' | 'outline' | 'solid' | 'default' = 'solid';
  @Input() public iconEnd?: IconProp;
  @Input() public iconStart?: IconProp;
  @Input() public isLoading = false;
  @Input() public label: string;
  @Input() public size: 'small' | 'default' | 'large' = 'default';
  @Input() public spinnerType?: SpinnerTypes;
  @Input() public id = '';

  @Output() clicked = new EventEmitter<any>();

}
