<div *ngIf="show"
     [@fade-toggle]="show"
     class="alert p-16 d-flex"
     [ngClass]="{
      'alert--error': type === 'error',
      'alert--info': type === 'info',
      'alert--success': type === 'success'
     }">
  <div class="alert--icon d-flex ion-justify-content-center ion-align-items-center mr-12">
    <fa-icon *ngIf="type === 'error'" [icon]="['far', 'exclamation-circle']"></fa-icon>
    <fa-icon *ngIf="type === 'info'" [icon]="['far', 'info-circle']"></fa-icon>
    <fa-icon *ngIf="type === 'success'" [icon]="['far', 'exclamation-circle']"></fa-icon>
  </div>
  <div class="alert--text">
    <small [innerHTML]="textContent"></small>
  </div>
</div>
