import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IconName } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'scout-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ScoutModalComponent {

  @Input() public title?: string;
  @Input() public subtitle?: string;
  @Input() public buttonLabel: string;
  @Input() public icon: IconName;

  constructor(public modalController: ModalController) {
  }
}
