<section class="d-flex ion-justify-content-start ion-align-items-center">
  <ng-container *ngFor="let value of values; let i = index">
    <label [for]="name + '-' + i"
           [ngStyle]="{width: 'calc(100%/' + values.length }"
           class="choice-chip mt-12 mb-8 mr-16 ion-text-center d-flex ion-justify-content-center ion-align-items-center ion-activatable ripple-parent">
      <input (change)="setAmount(value)"
             type="radio"
             [id]="name + '-' + i"
             [name]="name"
             [checked]="value.id === defaultValue.id">
      <span class="font-is-semibold">{{value.viewValue}}</span>
      <ion-ripple-effect type="bounded"></ion-ripple-effect>
    </label>
  </ng-container>
</section>
