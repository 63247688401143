import { API_PATHS, API_VERSION } from '@shared/services/data/data.endpoints';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Renderer2 } from '@angular/core';
import {
  LocationDetailInterface,
  ReservePosInterface,
  ScoutingInterface,
  SearchLocationsInterface,
  SearchProfileInterface,
} from '@shared/interfaces/shared.interface';
import { Observable } from 'rxjs';
import {
  ScoringCompensationInterface,
  ScoringHistoryDetailInterface,
  ScoringHistoryInterface,
  TaskDetailsInterface,
  TaskInterface
} from '@app/tabs/profile-tab/scores/scores.interface';
import { ScoutingFileInterface } from '@app/form/field/uploader/uploader.interface';
import { ScoutUserModel } from '@shared/authentication/user/user.model';
import { TokenRequest } from '@shared/authentication/oauth2/token-request';
import { TokenResponse } from '@shared/authentication/oauth2/token-response';
import { ActivatedRoute } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ScoutDataService {

  private static getUrlPath(path: string, apiPathsObj: object): string {
    return path.split('.').reduce((p, c) => p && p[c] || null, apiPathsObj);
  }

  private static buildUrlFor(path: string): string {
    const urlPath = this.getUrlPath(path, API_PATHS);

    return `${API_VERSION}/${urlPath}`;
  }

  constructor(private http: HttpClient) {
  }

  // user

  public login(params: TokenRequest): Observable<TokenResponse> {
    return this.http.post<any>(ScoutDataService.getUrlPath('USER.LOGIN', API_PATHS), params);
  }

  public getMe(): Observable<ScoutUserModel> {
    return this.http.get<any>(ScoutDataService.buildUrlFor('USER.ME'));
  }

  public patchUser(params: {}): Observable<ScoutUserModel> {
    return this.http.patch<ScoutUserModel>(ScoutDataService.buildUrlFor('USER.PATCH'), params);
  }

  public orderEnvelopes(amount: number): Observable<object> {
    return this.http.post(ScoutDataService.buildUrlFor('USER.ENVELOPES'), {amount});
  }

  // location specific

  public initStatusEvents(): Observable<any> {
    return this.http.get<any>(ScoutDataService.buildUrlFor('USER.STATUS'));
  }

  public searchForLocations({lat, lng}, radius: number, searchProfile: number): Observable<SearchLocationsInterface[]> {
    const params: HttpParams = new HttpParams()
      .set('latitude', lat)
      .set('longitude', lng)
      .set('radius', Number(radius).toString(10))
      .set('searchProfile', Number(searchProfile).toString(10));

    return this.http.get<any>(`${ScoutDataService.buildUrlFor('LOCATION.SEARCH')}?${params.toString()}`);
  }

  public getLocationDetails(id: string): Observable<LocationDetailInterface> {
    return this.http.get<LocationDetailInterface>(`${ScoutDataService.buildUrlFor('LOCATION.DETAILS')}/${id}`);
  }

  public reservePos(googleId: string): Observable<ReservePosInterface> {
    return this.http.post<ReservePosInterface>(ScoutDataService.buildUrlFor('SCOUTINGS.POS'), {googleId});
  }

  public getPosList(): Observable<ScoutingInterface[]> {
    return this.http.get<ScoutingInterface[]>(ScoutDataService.buildUrlFor('SCOUTINGS.POS'));
  }

  public updatePos(id: string, params: { transition?: string, comment?: string, remarks?: string }): Observable<any> {
    return this.http.patch(`${ScoutDataService.buildUrlFor('SCOUTINGS.POS')}/${id}`, params);
  }

  public uploadCardImage(id, params: ScoutingFileInterface): Observable<any> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    const url = `${ScoutDataService.buildUrlFor('SCOUTINGS.POS')}/${id}/${ScoutDataService.getUrlPath('SCOUTINGS.IMAGES', API_PATHS)}`;

    formData.append('file', params.file);
    formData.append('type', params.type);
    headers.set('Accept', 'multipart/form-data');
    headers.set('Content-Type', 'multipart/form-data');

    return this.http.post<ScoutingFileInterface>(url, formData, {headers});
  }

  // tasks

  public getTasks(): Observable<TaskInterface[]> {
    return this.http.get<TaskInterface[]>(`${ScoutDataService.buildUrlFor('TASKS')}?orderBy=-dueDate,sendSMS,-monetaryValue`);
  }

  public getTask(id: string): Observable<TaskDetailsInterface> {
    return this.http.get<TaskDetailsInterface>(`${ScoutDataService.buildUrlFor('TASKS')}/${id}`);
  }

  public updateTask(id: number, params: any): Observable<any> {
    return this.http.patch<any>(`${ScoutDataService.buildUrlFor('TASKS')}/${id}`, params);
  }

  // scorings

  public getScoringHistory(): Observable<ScoringHistoryInterface[]> {
    return this.http.get<any>(ScoutDataService.buildUrlFor('SCORINGS.HISTORY'));
  }

  public getScoringHistoryDetail(id: string): Observable<ScoringHistoryDetailInterface[]> {
    return this.http.get<any>(`${ScoutDataService.buildUrlFor('SCORINGS.HISTORY')}/${id}`);
  }

  public getScoutingCompensation(): Observable<ScoringCompensationInterface[]> {
    return this.http.get<any>(ScoutDataService.buildUrlFor('SCORINGS.SCORES'));
  }

  // search profiles

  public getSearchProfiles(): Observable<SearchProfileInterface[]> {
    return this.http.get<any>(ScoutDataService.buildUrlFor('SEARCH_PROFILE.ALL'));
  }

  // util

  public loadScript(): Promise<google.maps.MapsLibrary> {
    return new Loader({
      apiKey: environment.gMapsApiKey,
      version: 'weekly',
      libraries: [],
    }).importLibrary(
      'maps'
    );
  }

  public getIdFromUrl(route: ActivatedRoute) {
    return route.snapshot.params.id;
  }
}
