import { AbstractFieldComponent } from '@app/form/field/abstract-field.component';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { fadeToggle } from '@shared/util/animations/fade-toogle';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'scout-form-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  animations: [fadeToggle]
})
export class ScoutFormUploaderComponent extends AbstractFieldComponent {

  public file: File;

  @Input() public accept = 'image/*';
  @Input() public icon: IconProp;
  @Input() public label: string;

  @ViewChild('fileInput', {static: false}) public fileInput: ElementRef;

  @Output() public fileUpdate = new EventEmitter<File>();

  constructor() {
    super();
  }

  public fileBrowseHandler() {
    this.file = this.fileInput.nativeElement.files[0];
    this.fileUpdate.emit(this.file);
  }

  public deleteFile() {
    this.file = null;
    this.fileUpdate.emit(this.file);
  }

  public formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public isImage(file: File): boolean {
    return file.type.startsWith('image');
  }
}
