import { Component, Input } from '@angular/core';
import { fadeToggle } from '@shared/util/animations/fade-toogle';
import { ScoutAlertTypes } from '@shared/types/shared';

@Component({
  selector: 'scout-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [fadeToggle]
})
export class ScoutAlertComponent {
  @Input() public show: boolean;
  @Input() public type: ScoutAlertTypes;
  @Input() public title?: string;
  @Input() public textContent?: string;
}
