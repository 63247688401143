import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ScoutAuthenticationService } from '@shared/authentication/authentication.service';
import { throwError } from 'rxjs';

@Injectable()
export class ScoutHttpInterceptor implements HttpInterceptor {
  constructor(private authenticationService: ScoutAuthenticationService) {
  }

  private static handleInterception(request: HttpRequest<any>) {
    const url = environment.serverUrl;

    const headers = request.headers
      .append('Authorization', 'Bearer ' + (localStorage.getItem('oauth2_access_token') || ''));

    if (!headers.has('Content-Type')) {
      headers
        .append('Accept', 'application/json')
        .append('Content-Type', 'application/json');
    }

    request = request.clone({
      headers,
      url: url + request.urlWithParams,
    });

    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.responseType === 'text') {
      return next.handle(request);
    }

    if (request.url.endsWith('de.json')) {
      return next.handle(request);
    }

    if (request.url.includes('assets')) {
      return next.handle(request);
    }

    request = ScoutHttpInterceptor.handleInterception(request);

    return next.handle(request)
      .pipe(
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401 && error.error?.error === 'invalid_grant') {
              this.authenticationService.logout();
            }
          }

          return throwError(error);
        }),
      );
  }
}
