import { Routes } from '@angular/router';
import { LoginComponent } from '@app/account/login/login.component';
import { ScoutAuthenticationGuard } from '@shared/authentication/authentication.guard';
import { TutorialComponent } from '@app/tutorial/tutorial.component';

export const APP_ROUTES: Routes = [
  {
    path: 'benutzerkonto/einloggen',
    component: LoginComponent,
    data: {
      pageTitle: 'ACCOUNT.LOGIN.TITLE',
    },
  },
  {
    path: 'scoutbereich',
    canLoad: [ScoutAuthenticationGuard],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tutorial',
    canLoad: [ScoutAuthenticationGuard],
    component: TutorialComponent,
    data: {
      pageTitle: 'TUTORIAL.TITLE',
    },
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'scoutbereich/scouting',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'scoutbereich/scouting',
  }
];
