<ion-app>
  <ion-menu side="end"
            type="overlay"
            menuId="rightMenu"
            contentId="main"
            swipeGesture="false"
            (ionDidOpen)="oldSearchRadiusValue = filterService.searchRadiusValue.searchRadius;oldSearchProfileValue = filterService.activeSearchProfile.id"
            (ionWillClose)="triggerSearchRadiusUpdate()"
            (ionDidClose)="sidebarContentComponent = null">
    <ion-content>
      <ion-header>

        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button class="button-round font-size-md"
                        id="closeSidebarBtn"
                        color="primary"
                        (click)="menu.close()">
              <ion-icon slot="icon-only"
                        name="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ng-container *ngComponentOutlet="sidebarContentComponent"></ng-container>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main"></ion-router-outlet>
</ion-app>
