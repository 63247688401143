<div [formGroup]="formGroup"
     class="form-field-group">
  <ng-content></ng-content>
  <ion-label *ngIf="label"
             position="stacked">
    <span>{{label}}</span>
  </ion-label>
  <div *ngIf="hint"
       class="form-field-group--hint">
    <ion-text color="medium">
      <small>{{hint}}</small>
    </ion-text>
  </div>
  <scout-form-error-message *ngIf="formGroup"
                            [field]="formGroup.get(name)"
                            [translationPrefix]="translationPrefix"
                            [patternName]="patternName">
  </scout-form-error-message>
</div>
