<a href="tel:{{'GENERAL.PHONE_NUMBER' | transloco }}"
   id="profileOpenPhoneLinkBtn"
   class="button-link font-size-sm d-flex mt-16 ion-justify-content-between ion-align-items-center w-100 ion-activatable ripple-parent">
            <span>
              <span class="button-link--left-icon d-inline-flex ion-justify-content-center ion-align-items-center">
                <fa-icon slot="start"
                         [icon]="['fas', 'phone']">
              </fa-icon>
            </span>
              <span
                class="font-is-semibold ml-16 ion-text-nowrap">{{'GENERAL.LINKS.PHONE' | transloco}}</span>
            </span>
  <fa-icon slot="end"
           [icon]="['fas', 'chevron-right']">
  </fa-icon>
  <ion-ripple-effect type="bounded"></ion-ripple-effect>
</a>

<a href="http://join.jobodo.de"
   id="profileOpenWebsiteLinkBtn"
   target="_blank"
   rel="noopener"
   class="button-link font-size-sm d-flex mt-16 ion-justify-content-between ion-align-items-center w-100 ion-activatable ripple-parent">
            <span>
              <span class="button-link--left-icon d-inline-flex ion-justify-content-center ion-align-items-center">
                <fa-icon slot="start"
                         [icon]="['fas', 'external-link']">
              </fa-icon>
            </span>
              <span
                class="font-is-semibold ml-16 ion-text-nowrap">{{'GENERAL.LINKS.WEB' | transloco}}</span>
            </span>
  <fa-icon slot="end"
           [icon]="['fas', 'chevron-right']">
  </fa-icon>
  <ion-ripple-effect type="bounded"></ion-ripple-effect>
</a>
