import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OutletTypes } from '@shared/types/shared';

@Injectable({
  providedIn: 'root'
})
export class OutletService {

  private activeSidebarComponent = new Subject<OutletTypes>();
  public activeSidebarComponent$ = this.activeSidebarComponent.asObservable();

  public setComponent(component: OutletTypes): void {
    this.activeSidebarComponent.next(component);
  }

  public getComponent(): Observable<OutletTypes> {
    return this.activeSidebarComponent$;
  }
}
