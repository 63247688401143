<main class="p-32">
  <div class="ion-text-center">
    <fa-icon [icon]="['fad', icon]"
             class="colored-icon">
    </fa-icon>

    <ion-text *ngIf="title"
              color="dark">
      <h4 class="ion-text-center font-is-semibold font-size-md pt-8">
        <span [innerHTML]="title"></span>
      </h4>
    </ion-text>
    <ion-text *ngIf="subtitle"
              color="medium"
              class="ion-text-center">
      <small [innerHTML]="subtitle"></small>
    </ion-text>
  </div>
</main>

<footer class="p-16">
  <scout-button [color]="'primary'"
                [fill]="'clear'"
                [id]="'simpleModalCloseBtn'"
                (clicked)="modalController.dismiss()"
                [label]="buttonLabel">
  </scout-button>
</footer>
