<ng-container *transloco="let t">
  <ion-header>
    <ion-toolbar>
      <ion-title class="ion-text-uppercase font-is-bold"
                 color="primary">
        <span>{{t('TITLE')}}</span>
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <form [formGroup]="loginForm"
          (ngSubmit)="onSubmit()"
          [ngClass]="{'is-disabled': loginForm.disabled}"
          class="horizontal-form p-16"
          novalidate>
      <div>
        <div class="brand-logo my-16 d-flex--center-center">
          <img src="/assets/images/icon/logo-slim.svg">
        </div>

        <scout-alert [show]="userPasswordCombinationError"
                     [type]="'error'"
                     [textContent]="t('ACCOUNT.LOGIN.FORM.ERROR.USER_PASSWORD_COMBINATION')">
        </scout-alert>

        <div>
          <scout-form-field-group [label]="t('ACCOUNT.LOGIN.FORM.FIELD.USER_ID.LABEL')"
                                  [formGroup]="loginForm"
                                  [name]="'userId'">
            <scout-form-input [formGroup]="loginForm"
                              [name]="'userId'">
            </scout-form-input>
          </scout-form-field-group>
        </div>
        <div class="pt-16">
          <scout-form-field-group [label]="t('ACCOUNT.LOGIN.FORM.FIELD.PASSWORD.LABEL')"
                                  [formGroup]="loginForm"
                                  [name]="'password'">
            <scout-form-input [formGroup]="loginForm"
                              [type]="'password'"
                              [name]="'password'">
            </scout-form-input>
          </scout-form-field-group>
        </div>
        <div class="d-flex ion-justify-content-end">
          <a href="tel:{{t('GENERAL.PHONE_NUMBER')}}"
             class="py-12 font-size-sm">
            <span>{{t('ACCOUNT.LOGIN.FORM.ACTION.SECONDARY')}}</span>
          </a>
        </div>
      </div>

      <scout-button [buttonType]="'submit'"
                    [disabled]="loginForm.disabled"
                    [isLoading]="loginForm.disabled"
                    [id]="'loginFormSubmitBtn'"
                    [label]="t('ACCOUNT.LOGIN.FORM.ACTION.PRIMARY')">
      </scout-button>
    </form>
  </ion-content>

</ng-container>
