export class ScoutUserEmbeddedRegionModel {
  constructor(public name?: string,
              public id?: string,
              /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
              public radius?: number,
              public zip_codes?: number[],
              public created_at?: Date,
              public updated_at?: Date,
              /* eslint-enable */
              public latitude?: number,
              public longitude?: number) {
  }
}

export class ScoutUserEmbeddedModel {
  constructor(public regions?: ScoutUserEmbeddedRegionModel[],
              public scoutings?: number,
              public score?: number,
              public jwtToken?: string) {
  }
}

export class ScoutUserModel {
  constructor(public id?: string,
              public email?: string,
              public roles?: string[],
              public enabled?: boolean,
              public displayName?: string,
              public firstname?: string,
              public lastname?: string,
              public biography?: string,
              public lastSeen?: Date,
              public hasSeenTutorial?: Date,
              public hasSeenBriefing?: Date,
              public password?: string,
              public gender?: string,
              public envelopeRequestedDate?: Date,
              public latitude?: number,
              public longitude?: number,
              public locationUpdate?: Date,
              // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
              public _embedded?: ScoutUserEmbeddedModel,
  ) {
  }
}
