<ng-container [formGroup]="formGroup">
  <ion-textarea [formGroup]="formGroup"
                [placeholder]="placeholder"
                [attr.id]="name"
                [cols]="cols"
                inputmode="text"
                enterkeyhint="enter"
                debounce="400"
                [formControlName]="name">
  </ion-textarea>
</ng-container>
