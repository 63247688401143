import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { ScoutFormChoiceChipsComponent } from '@app/form/field/choice-chips/choice-chips.component';
import { ScoutFormErrorMessageComponent } from './shared/error-message/error-message.component';
import { ScoutFormFieldGroupComponent } from '@app/form/field-group/field-group.component';
import { ScoutFormInputComponent } from './field/input/input.component';
import { ScoutFormTextareaComponent } from '@app/form/field/textarea/textarea.component';
import { ScoutFormUploaderComponent } from '@app/form/field/uploader/uploader.component';
import { ScoutMediaPreviewDirective } from './shared/media-preview.directive';
import { TranslocoModule } from '@ngneat/transloco';

const ScoutFormComponents = [
  ScoutFormChoiceChipsComponent,
  ScoutFormErrorMessageComponent,
  ScoutFormFieldGroupComponent,
  ScoutFormInputComponent,
  ScoutFormTextareaComponent,
  ScoutFormUploaderComponent,
  ScoutMediaPreviewDirective,
];

@NgModule({
  declarations: [
    ...ScoutFormComponents,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  exports: [
    ...ScoutFormComponents,
  ],
})
export class ScoutFormModule {
}
