import { NgModule } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ICONS } from '@app/icons/icons';


@NgModule({
  exports: [FontAwesomeModule],
})

export class IconsModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(...ICONS);
  }
}
