import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { fadeToggle } from '@shared/util/animations/fade-toogle';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { ScoutAuthenticationService } from '@shared/authentication/authentication.service';
import { ScoutUserEventService } from '@shared/authentication/user/user-event.service';
import { ScoutUserModel } from '@shared/authentication/user/user.model';
import { ScoutUserService } from '@shared/authentication/user/user.service';

@Component({
  selector: 'scout-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeToggle],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  public loginForm= this.fb.group({
    userId: ['', Validators.required],
    password: ['', Validators.required],
  });
  public userPasswordCombinationError: boolean;

  constructor(private fb: FormBuilder,
              private authService: ScoutAuthenticationService,
              private userService: ScoutUserService,
              private navController: NavController,
              private userEventService: ScoutUserEventService,
              private cdRef: ChangeDetectorRef) {
    if (userService.isLoggedIn()) {
      void this.navController.navigateForward(['/']);
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  public onSubmit(): void {
    this.loginForm.markAllAsTouched();

    if (this.loginForm.invalid) {
      this.cdRef.markForCheck();
      return;
    }
    this.loginForm.disable();
    this.cdRef.markForCheck();

    const parameters = {
      grant_type: 'password',
      client_id: environment.oauthClientId,
      client_secret: environment.oauthClientSecret,
      username: this.loginForm.value.userId,
      password: this.loginForm.value.password,
    };
    this.authService
      .login(parameters)
      .subscribe(() => {
        this.loginForm.reset();
        this.loginForm.enable();
        this.cdRef.markForCheck();
        this.userService.load()
          .subscribe((user: ScoutUserModel) => {
            this.userEventService.triggerLogin(user);
            this.userPasswordCombinationError = false;
            const redirectUrl = !this.userService.getUser().hasSeenTutorial ? '/tutorial' : this.authService.getRedirectUrl();
            ScoutAuthenticationService.clearRedirectUrl();
            this.cdRef.markForCheck();
            return this.navController.navigateForward([redirectUrl]);
          });
      }, (error: HttpErrorResponse) => {
        this.loginForm.enable();
        this.cdRef.markForCheck();

        if (error.status === 400) {
          this.userPasswordCombinationError = true;
        }
      });
  }
}
