<ion-button [color]="color"
            [id]="id"
            [expand]="expand"
            [fill]="fill"
            [size]="size"
            [disabled]="disabled"
            (click)="clicked.emit($event)"
            [type]="buttonType">
  <ion-spinner *ngIf="isLoading"
               [name]="spinnerType">
  </ion-spinner>

  <fa-icon *ngIf="iconStart && !isLoading"
           slot="start"
           [icon]="iconStart">
  </fa-icon>

  <span *ngIf="label && !isLoading">{{label}}</span>

  <fa-icon *ngIf="iconEnd && !isLoading"
           slot="end"
           [icon]="iconEnd">
  </fa-icon>
</ion-button>
