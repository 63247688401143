import { AbstractControl } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { fadeToggle } from '@shared/util/animations/fade-toogle';
import type { HashMap } from '@ngneat/transloco';

@Component({
  selector: 'scout-form-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  animations: [fadeToggle]
})
export class ScoutFormErrorMessageComponent {

  @Input() field: AbstractControl;
  @Input() patternName?: string;
  @Input() translationPrefix?: string;

  public filterErrors(field: AbstractControl | null) {
    if (!field?.errors) {
      return [];
    }

    const result: { key: string; val: HashMap }[] = [];
    if (field.errors) {
      const keys = Object.keys(field.errors);

      if (keys) {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const matches = key.match(/Value$/g);

          if (!matches) {
            result.push({
              key,
              val: field.errors[key],
            });
          }
        }
      }
    }

    return result;
  }
}
