export class SerializationUtil {
  static toInstance<T>(object: T, json: any): T {
    let jsonObject: any;

    if (typeof json === 'string') {
      jsonObject = JSON.parse(json);
    } else {
      jsonObject = json;
    }

    /* eslint-disable @typescript-eslint/dot-notation */
    if (typeof object['fromJSON'] === 'function') {

      object['fromJSON'](jsonObject);
    } else {
      for (const key of Object.keys(jsonObject)) {
        object[key] = jsonObject[key] !== null ? jsonObject[key] : undefined;
      }
    }

    /* eslint-enable @typescript-eslint/dot-notation */
    return object;
  }
}
