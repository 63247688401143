import { Component, Input } from '@angular/core';
import { ScoutFormInputTypes } from './input';
import { AbstractFieldComponent } from '@app/form/field/abstract-field.component';

@Component({
  selector: 'scout-form-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class ScoutFormInputComponent extends AbstractFieldComponent {

  @Input() public type: ScoutFormInputTypes = 'text';
  @Input() public placeholder = '';
}
