import { getUnixTime } from 'date-fns';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ScoutDataService } from '@shared/services/data/data.service';
import { ScoutUserModel } from '@shared/authentication/user/user.model';
import { ScoutUserStorage } from '@shared/authentication/user/user-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ScoutUserService {
  constructor(private http: HttpClient,
              private userStorage: ScoutUserStorage,
              private dataService: ScoutDataService) {
    if (!this.isLoggedIn()) {
      this.clear();
    }
  }

  public getUser(): ScoutUserModel {
    return this.userStorage.getUser();
  }

  public hasUser(): boolean {
    return this.getUser() instanceof ScoutUserModel;
  }

  public hasValidToken(): boolean {
    const token = localStorage.getItem('oauth2_grant_type');
    const timeout = localStorage.getItem('oauth2_timeout');

    if (token && timeout) {
      return (getUnixTime(new Date()) <= parseInt(timeout, 10));
    }

    return false;
  }

  public isLoggedIn(): boolean {
    const grantType = localStorage.getItem('oauth2_grant_type');

    return (this.hasValidToken() && grantType !== 'client_credentials');
  }

  public load(): Observable<ScoutUserModel> {
    const subject = new Subject();

    this.dataService
      .getMe()
      .subscribe((user: ScoutUserModel) => {
        this.parse(user);
        subject.next(this.userStorage.getUser());
        subject.complete();
      }, (error: HttpErrorResponse) => {
        this.clear();
        subject.error(error);
        subject.complete();
      });

    return subject.asObservable();
  }

  private parse(user: ScoutUserModel): void {
    if (!this.isLoggedIn()) {
      this.clear();

      return;
    }

    this.userStorage.setUser(user);
  }

  private clear(): void {
    this.userStorage.clearUser();
  }
}
