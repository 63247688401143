import * as Sentry from '@sentry/angular';
import localeDE from '@angular/common/locales/de';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IONIC_CONFIG } from '@app/ionic.config';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { LoginComponent } from './account/login/login.component';
import { registerLocaleData } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import { ScoutHttpInterceptor } from '@shared/util/http/http-interceptor.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from '@shared/shared.module';
import { TutorialComponent } from '@app/tutorial/tutorial.component';
import { TranslocoRootModule } from '@app/transloco-root.module';

registerLocaleData(localeDE);

const AppProviders = [
  {
    provide: RouteReuseStrategy,
    useClass: IonicRouteStrategy,
  },
  {
    provide: LOCALE_ID,
    useValue: 'de',
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ScoutHttpInterceptor,
    multi: true,
  },
  {
    provide: ErrorHandler,
    useValue: environment.production ? Sentry.createErrorHandler({
      showDialog: false,
    }) : new ErrorHandler(),
  },
  {
    provide: Window,
    useValue: window
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TutorialComponent,
  ],
  imports: [
    SharedModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(IONIC_CONFIG),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    TranslocoRootModule,
  ],
  providers: [
    ...AppProviders,
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {

}
