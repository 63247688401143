<ion-content>
  <ion-slides pager="true"
              class="tutorial-slider h-100"
              [options]="sliderOptions"
              [pager]="true"
              #tutorialSlider>
    <ion-slide class="slide p-16 d-flex flex-column ion-justify-content-between">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <h4 class="font-is-semibold"
                [innerHTML]="'TUTORIAL.SLIDE_1.TITLE' | transloco">
            </h4>
          </ion-col>
          <ion-col size="12">
            <fa-icon [icon]="['fad', 'street-view']"
                     class="colored-icon">
            </fa-icon>
            <div [innerHTML]="'TUTORIAL.SLIDE_1.MESSAGE' | transloco"
                 class="font-size-md mt-20">
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <scout-button [label]="'GENERAL.NEXT' | transloco"
                    [id]="'tutorialFirstSlideNextBtn'"
                    (clicked)="tutorialSlider.slideNext()">
      </scout-button>
    </ion-slide>
    <ion-slide class="slide p-16 d-flex flex-column ion-justify-content-between">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <h4 class="font-is-semibold"
                [innerHTML]="'TUTORIAL.SLIDE_2.TITLE' | transloco">
            </h4>
          </ion-col>
          <ion-col size="12">
            <ion-text color="primary"
                      style="font-size: var(--scout-big-colored-icon-size);">
              <fa-icon [icon]="['fas', 'box']"></fa-icon>
            </ion-text>
            <div [innerHTML]="'TUTORIAL.SLIDE_2.MESSAGE' | transloco"
                 class="font-size-md mt-20">
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <scout-button [label]="'GENERAL.NEXT' | transloco"
                    [id]="'tutorialSecondSlideNextBtn'"
                    (clicked)="tutorialSlider.slideNext()">
      </scout-button>
    </ion-slide>
    <ion-slide class="slide p-16 d-flex flex-column ion-justify-content-between">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <h4 class="font-is-semibold mb-12"
                [innerHTML]="'TUTORIAL.SLIDE_3.TITLE' | transloco">
            </h4>
          </ion-col>
          <ion-col size="12">
            <fa-icon [icon]="['fad', 'phone']"
                     class="colored-icon switch-colors">
            </fa-icon>
            <div [innerHTML]="'TUTORIAL.SLIDE_3.MESSAGE' | transloco"
                 class="font-size-md mt-20">
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <scout-button [label]="'TUTORIAL.SLIDE_3.ACTION' | transloco"
                    [id]="'tutorialThirdSlideNextBtn'"
                    [disabled]="isLoading"
                    [isLoading]="isLoading"
                    (clicked)="finishTutorial()">
      </scout-button>
    </ion-slide>
  </ion-slides>
</ion-content>
