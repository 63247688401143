export const environment = {
  name: 'qs',
  production: true,
  serverUrl: 'https://qs-backend-scout.jobodo.de/',
  gMapsApiKey: 'AIzaSyDW0HVrUlPp5TCIKWwggeVOqmNu6tGqTW0',
  oauthClientId: '1_3bcbxd9e24g0gk4swg0kwgcwg4o8k8g4g888kwc44gcc0gwwk4',
  oauthClientSecret: '4ok2x70rlfokc8g0wws8c8kwcokw80k44sg48goc0ok4w0so0k',
  sentryId: 'https://112257eeb42042f099407c3c12c81b59@o390785.ingest.sentry.io/5235869',
  events: {
    source: 'https://qs-mercure-scout.jobodo.de/.well-known/mercure',
    channels: [
      {
        id: 'locations',
        url: 'http://scout.app/locations',
      },
      {
        id: 'users',
        url: 'http://scout.app/users',
      },
      {
        id: 'tasks',
        url: 'http://scout.app/tasks',
      },
    ]
  }
};
